.docx-container .docx-wrapper{
  width: 100% !important;
  padding: 15px !important;
  // overflow-x: auto !important;
}
 
.docx-container .docx-wrapper-wrapper .docx-wrapper .docx-wrapper_normal div{
  width: 100% !important;
  height: auto !important;
}
 
.docx-container .docx-wrapper-wrapper .docx-wrapper .docx-wrapper_normal div img{
  width: 100% !important;
  height: auto !important;
}
 
.docx-container .docx-wrapper-wrapper .docx-wrapper .docx-wrapper_bodytext div{
  width: 100% !important;
  height: auto !important;
}
.docx-container .docx-wrapper-wrapper .docx-wrapper .docx-wrapper_bodytext div img{
  width: 100% !important;
  height: auto !important;
}
 
.kiJKRu {
  display: none !important;
}

.react-pdf__Page__textContent{
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}