.otp-boxes {
  width: 10%;
  height: 3.5rem;
  font-size: 2rem;
  margin: 0.6rem;
  border-radius: 0.3rem;
  padding: 0.3rem;
  text-align: center;
  font: map-get($fonts, h4-regular) !important;

  :focus {
    border-radius: 0.5rem;
    outline: none;
    border: none;
  }

  @media (max-device-width: 425px) and (min-device-width: 320px) {
    height: 2rem;
    width: 14% !important;
    font: map-get($fonts-mobile, h2) !important;
    font-size: 1rem !important;
  }
}

.falcon-logo-teams {
  width: 8rem;
  height: 3.375rem;
}

.falcon-logo {
  width: 8rem;
  height: 3.375rem;

  @media (min-width: 883px) and (max-width: 1224px) {
    width: 5rem;
    height: 2.2rem;
  }

  @media (min-width: 320px) and (max-width: 883px) {
    width: 10rem;
    height: 4.675rem;
  }

  @media (max-width: 320px) {
    width: 10rem;
    height: 4.675rem;
  }
}

.container-width {
  @media (max-width: 883px) {
    width: 80% !important;
  }

  @media (min-width: 883px) and (max-width: 1224px) {
    width: 50% !important;
  }
}

.login-form-container-teams {
  // margin-top: 1rem;
  width: 75%;
  overflow: hidden;
  padding: 1.5rem;
}


.login-form-container {
  margin-top: 1rem;
  width: 75%;
  overflow: hidden;
  padding: 2rem;
  padding-top: 1rem !important;
  padding-bottom: 0 !important;
}

.falcon-m-icon {
  display: flex;
  width: 100%;
}


.img-tick {
  width: 0.85rem !important;
  height: 0.85rem !important;

  @media (max-width: 835px) {
    width: 0.7rem !important;
    margin-bottom: 1rem !important;
  }
}

.tick-avatar {
  border-radius: 50% !important;
  height: 0.9rem !important;
  width: 0.9rem !important;

  @media (max-width: 835px) {
    height: 0.6rem !important;
    width: 0.6rem !important;
  }
}

.login-background {
  border: 2px solid map-get($colors, login-border) !important;
  backdrop-filter: blur(28px);
  -webkit-backdrop-filter: blur(28px);
  z-index: 9999;

  @media (max-width: 835px) {
    border: none !important;
  }
}

.login-bg-icon {
  width: 36%;
  top: 23%;
  left: 17%;
}

.login-form-container-teams~.login-rounded-top {
  top: -68% !important;
  right: -28% !important;
}

.login-rounded-top {
  position: fixed;
  right: 0;
  z-index: 0; 
  pointer-events: none; 
}

.login-form-container-teams~.login-rounded-bottom {
  left: -28% !important;
  bottom: -68% !important;
}

.login-rounded-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
}

.login-rounded-middle {
  position: absolute;
  width: 5rem;
  right: 31%;
  bottom: 19%;
  height: 5rem;
  border-radius: 50%;
  background-color: #FFA114;

  @media (max-width: 835px) {
    display: none;
  }

}

.login-app-download-icon {
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 1rem 0.5rem;
  opacity: 1;
  position: relative;
  // padding: 1.5rem;
  text-decoration: none !important;
}

.login-button-border-color {
  border: 0.03125rem solid #DCE1E9 !important;
}

.signin-success-color {
  color: #7F7F7F
}

.SSO-inactive-screen-background {
  background-color: #FDEDEE;
}

.signin-left-bg {
  background: linear-gradient(to bottom left, #E3F4FF, #FFFDE8);
}

.signup-left-bg {
  background: transparent linear-gradient(221deg, #FFEBE3 0%, #FFFDE8 100%) 0% 0% no-repeat padding-box;
}

.unauth-screen-button-gredient {
  background: linear-gradient(to right, #0A2880, #1450FF);
}

.sign-up-button {
  font: map-get($fonts, h5-regular);
  border-radius: 0.375rem;
  padding: 0.7rem 2.5rem !important;
  outline: inherit;
  margin: 0.75rem 0;
  border: 1px solid #DCE1E9 !important;
  color: #91A3C1 !important;
  background: #F8FAFF !important;
  margin-right: 0px !important;
}