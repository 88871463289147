.settings-mobile-paper-border {
  border-bottom: 1px solid map-get($colors, settings-border-box) !important;
  border-left: 1px solid map-get($colors, settings-border-box) !important;
  border-right: 1px solid map-get($colors, settings-border-box) !important;
}

.settings-paper-border {
  border: 1px solid map-get($colors, settings-border-box) !important;
}

.settings-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.inner-settings-container {
  background-color: #ffffff;
  width: 100%;
  height: 94%;
}

.checked-color {
  color: #22313f !important;
}

.back-icon-height {
  height: 1.3rem !important;
}

.counter {
  border: 0.05rem solid map-get($colors, secondary-btn-highlight-hover);
  width: fit-content;

  &-inverted {
    border: 0.05rem solid map-get($colors, secondary);
    width: fit-content;
    color: map-get($colors, secondary);
  }
}

.change-password-vertical-line {
  border-right: 2px solid map-get($colors, settings-border-box) !important;
}

.settings-text-field-border {
  border: 1px solid map-get($colors, text-field-focused) !important;
}

.change-password-horizontal-line {
  border-bottom: 2px solid map-get($colors, settings-border-box) !important;
}

.unsubscribe-hr {
  border-width: 100%;
}

.general-mobile-hr {
  border: 0.5px solid #0D0D0D;
}

.mobile-list-border-bottom {
  border-bottom: 1px solid #F0F0F0;
}

.schedule-report-border {
  border: 1px solid #DCE1E9 !important;
}

.number-input-container {
  display: flex;
  align-items: center;
  border: 0.03125rem solid map-get($colors, primary-divider);
  border-radius: 4px;
  width: 60px; 
  background-color: #ffffff;
}

.number-input-field {
  flex: 1;
  width: 70%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #333;
  background-color: transparent;
  padding: 0.5rem;
}

.number-input-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.number-control-btn {
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  cursor: pointer;
  color: #555;
  padding: 0.25rem;
  border-left: 0.03125rem solid map-get($colors, primary-divider);
}

.number-control-btn:disabled {
  color: #ccc;
  cursor: not-allowed;
}
