@import "./fonts.scss";
@import "./variables.scss";
@import "./theme/side-bar.scss";
@import "./theme/conversation.scss";
@import "./theme/conversation-new.scss";
@import "./theme/button.scss";
@import "./theme/shared.scss";
@import "./theme/profile.scss";
@import "./theme/authpages.scss";
@import "./theme/dashboard.scss";
@import "./theme/settings.scss";
@import "./theme/mat-ui.scss";
@import "./theme/plans.scss";
@import "./theme/custom.scss";
@import "./theme/layout.scss";
@import "./theme/toaster.scss";
@import "./theme/signup.scss";
@import "./theme/datetimepicker.scss";
@import "./theme/stepper.scss";
@import "./theme/tiers.scss";
@import "./theme/bottom-drawer-mobile.scss";
@import "./theme/reports.scss";
@import "./theme/circular-bar-chat.scss";
@import "./theme/grid.scss";
@import "./theme/groupchat.scss";
@import "./theme/import-contacts.scss";
@import "./theme/customTooltip.scss";
@import "./theme/badge-notif.scss";
@import "./theme/contact.scss";
@import "./theme/amplify.scss";
@import "./theme/securefile.scss";


body {
  background: map-get($colors, primary-background) !important;
  font: normal normal normal 9px/11px silkaregular;
  overflow: hidden !important;
}

a {
  text-decoration: none;
}

a.underline {
  text-decoration: underline;
  color: #1883ba;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(:focus-visible) {
  outline: none;
}

*:focus-visible {
  outline-color: -webkit-focus-ring-color;
  outline-width: 1px;
}

// background

.highlight-hover {

  &:hover,
  &:focus {
    background-color: map-get($colors, secondary-highlight-hover);
  }
}

.box-shadow {
  &-none {
    box-shadow: none !important;
  }

  &-primary-header-shadow {
    box-shadow: 0 0.5rem 1.5rem map-get($colors, primary-header-shadow) !important;
  }

  &-display-background-shadow {
    box-shadow: 0 1rem 2.5rem map-get($colors, display-background) !important;
  }

  &-unread-label {
    box-shadow: 0px 1px 2px 0px map-get($colors, primary-divider) !important;
  }
}

.paper-background {
  background: map-get($colors, secondary) 0% 0% no-repeat padding-box;
  box-shadow: none;
}

.active {
  background: linear-gradient(to right,
      map-get($colors, primary-success) 0.25rem,
      map-get($colors, secondary) 0%) 0px 4px no-repeat padding-box !important;
}

.conv-card-active {
  background: linear-gradient(to right,
      map-get($colors, primary-success) 0.25rem,
      map-get($colors, conv-card-highlight) 0%) 0px 4px no-repeat padding-box !important;
}

.active-blocked {
  background: linear-gradient(to right,
      map-get($colors, primary-danger) 0.25rem,
      map-get($colors, conv-card-highlight) 0%) 0px 4px no-repeat padding-box !important;
}

.blocked {
  background: linear-gradient(to right,
      map-get($colors, primary-danger) 0.25rem,
      map-get($colors, secondary) 0%) 0px 4px no-repeat padding-box !important;
}

.inactive {
  background: linear-gradient(to right,
      map-get($colors, primary-inactive) 0.25rem,
      map-get($colors, secondary) 0%) 0px 4px no-repeat padding-box !important;
}

.active-unsubscribed {
  background: linear-gradient(to right,
      map-get($colors, primary-warning) 0.25rem,
      map-get($colors, conv-card-highlight) 0%) 0px 4px no-repeat padding-box !important;
}

.unsubscribed {
  background: linear-gradient(to right,
      map-get($colors, primary-warning) 0.25rem,
      map-get($colors, secondary) 0%) 0px 4px no-repeat padding-box !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

// dispaly

.d-hover {
  visibility: hidden !important;

  &:hover {
    visibility: visible !important;
  }

  @media (max-width: 835px) {
    visibility: visible !important;
  }
}

.d-none {
  display: none !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block;
}

.flex-1 {
  flex: 1 !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

.c-pointer {
  cursor: pointer;
}

.c-grab {
  cursor: grab;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-item-center {
  justify-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction {
  flex-direction: column !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

@for $i from 0 through 12 {
  .flex-grow-#{$i} {
    flex-grow: $i;
  }
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: sticky !important;
}

.o-x-auto {
  overflow-x: auto !important;
}

.o-y-auto {
  overflow-y: auto !important;
}

.o-y-scroll {
  overflow-y: scroll !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-x-hidden {
  overflow-x: hidden !important;
}

.o-y-hidden {
  overflow-y: hidden !important;
}

//opacity

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.25 !important;
}

.opacity-2 {
  opacity: 0.5 !important;
}

.opacity-3 {
  opacity: 0.75 !important;
}

.opacity-3-5 {
  opacity: 0.90 !important;
}

.opacity-4 {
  opacity: 1 !important;
}

// text

.w-space {
  white-space: nowrap !important;
}

.w-inherit {
  width: inherit !important;
}

.w-fill {
  width: -webkit-fill-available !important;
  width: -moz-available !important; // firefox
}

.h-fill {
  height: -webkit-fill-available;
}

.max-h-fill {
  max-height: -webkit-fill-available;
}

.h-fit {
  height: fit-content !important;
}

.h-auto {
  height: auto;
}

.font-bold {
  font-weight: bold !important;
}

.font-unset {
  font: unset !important;
}

.t-decoration-underline {
  text-decoration: underline !important;
}

.t-align-right {
  text-align: right !important;
}

.t-align-left {
  text-align: left !important;
}

.t-align-center {
  text-align: center !important;
}

.t-align-initial {
  text-align: initial !important;
}

.align-center {
  align-items: center !important;
}

.align-start {
  align-items: start !important;
}

.align-end {
  align-items: end !important;
}

.text-style {
  text-decoration: none !important;
}

.f-w-bold {
  font-weight: bold !important;
}

//Auto
.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

//Word-break
.w-break {
  word-break: break-all;
}

@for $i from 0 through 128 {
  //Margin

  .m-#{$i} {
    margin: $i * 0.5rem !important;
  }

  .mt-#{$i} {
    margin-top: $i * 0.5rem !important;
  }

  .mr-#{$i} {
    margin-right: $i * 0.5rem !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 0.5rem !important;
  }

  .ml-#{$i} {
    margin-left: $i * 0.5rem !important;
  }

  .ml-p-#{$i} {
    margin-left: $i * 1% !important;
  }

  .mx-#{$i} {
    margin-left: $i * 0.5rem !important;
    margin-right: $i * 0.5rem !important;
  }

  .my-#{$i} {
    margin-top: $i * 0.5rem !important;
    margin-bottom: $i * 0.5rem !important;
  }

  //margin negative

  .mn-#{$i} {
    margin: $i * -0.5rem !important;
  }

  .mtn-#{$i} {
    margin-top: $i * -0.5rem !important;
  }

  .mrn-#{$i} {
    margin-right: $i * -0.5rem !important;
  }

  .mbn-#{$i} {
    margin-bottom: $i * -0.5rem !important;
  }

  .mln-#{$i} {
    margin-left: $i * -0.5rem !important;
  }

  .mxn-#{$i} {
    margin-left: $i * -0.5rem !important;
    margin-right: $i * -0.5rem !important;
  }

  .myn-#{$i} {
    margin-top: $i * -0.5rem !important;
    margin-bottom: $i * -0.5rem !important;
  }

  //Padding

  .p-#{$i} {
    padding: $i * 0.5rem !important;
  }

  .pt-#{$i} {
    padding-top: $i * 0.5rem !important;
  }

  .pr-#{$i} {
    padding-right: $i * 0.5rem !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * 0.5rem !important;
  }

  .pl-#{$i} {
    padding-left: $i * 0.5rem !important;
  }

  .px-#{$i} {
    padding-left: $i * 0.5rem !important;
    padding-right: $i * 0.5rem !important;
  }

  .py-#{$i} {
    padding-top: $i * 0.5rem !important;
    padding-bottom: $i * 0.5rem !important;
  }

  // Right

  .r-#{$i} {
    right: $i * 0.5rem !important;
  }

  //Left

  .l-#{$i} {
    left: $i * 0.5rem !important;
  }

  //Top

  .t-#{$i} {
    top: $i * 0.5rem !important;
  }

  .t-n-#{$i} {
    top: $i * -0.5rem !important;
  }

  //Bottom

  .b-#{$i} {
    bottom: $i * 0.5rem !important;
  }

  // Width

  .w-#{$i} {
    width: $i * 0.5rem !important;
  }

  .max-w-#{$i} {
    width: fit-content !important;
    max-width: $i * 0.5rem !important;
  }

  .min-w-#{$i} {
    width: fit-content !important;
    min-width: $i * 0.5rem !important;
  }

  //Height

  .h-#{$i} {
    height: $i * 0.5rem !important;
  }

  .min-h-#{$i} {
    min-height: $i * 0.5rem !important;
  }

  .max-h-#{$i} {
    height: fit-content !important;
    max-height: $i * 0.5rem !important;
  }

  //border
  .br-#{$i} {
    border-radius: $i * 0.25rem !important;
  }

  .brt-#{$i} {
    border-radius: $i * 0.25rem $i * 0.25rem 0 0 !important;
  }

  .brb-#{$i} {
    border-radius: 0 0 $i * 0.25rem $i * 0.25rem !important;
  }

  .br-p-#{$i} {
    border-radius: $i *1% !important;
  }

}

@for $i from 1 through 100 {
  // Right

  .r-p-#{$i} {
    right: $i * 1% !important;
  }

  //Left

  .l-p-#{$i} {
    left: $i * 1% !important;
  }

  //Top

  .t-p-#{$i} {
    top: $i * 1% !important;
  }

  //Bottom

  .b-p-#{$i} {
    bottom: $i * 1% !important;
  }

  // Width

  .w-p-#{$i} {
    width: $i * 1% !important;
  }

  .w-v-#{$i} {
    width: $i * 1vw !important;
  }

  //Height

  .h-p-#{$i} {
    height: $i * 1% !important;
  }

  .h-v-#{$i} {
    height: $i * 1vh !important;
  }

  .max-fit-h-v-#{$i} {
    max-height: $i * 1vh !important;
    height: fit-content;
  }

  .min-fit-w-#{$i} {
    min-width: $i * 0.5rem !important;
    width: fit-content !important;
  }

  .opacity-p-#{$i} {
    opacity: $i * 1%;
  }
}

@each $key, $val in $colors {
  //Background Color

  .bg-color-#{$key} {
    background: $val !important;
  }

  // Font Color
  .color-#{$key} {
    color: $val !important;
  }

  .hover-color-#{$key}:hover {
    background: $val !important;
  }
}

@each $key, $val in $fonts {
  .#{$key} {
    font: $val !important;
  }
}

@media (max-width: 835px) {
  @each $key, $val in $fonts-mobile {
    .#{$key} {
      font: $val !important;
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;

  @media (max-width: 639px) {
    width: 0.2rem;
  }

  /* Track */
  &-track {
    background: transparent;
    border-radius: 0.25rem;

    &:hover {
      background: map-get($colors, secondary-main);
    }
  }

  /* Handle */
  &-thumb {
    max-height: 5rem;
    width: 5rem;
    border-radius: 0.25rem;
    background: map-get($colors, secondary-light);

    /* Handle on hover */
    &:hover {
      background: map-get($colors, scroll-thumb-hover);
    }
  }
}

/* Borders*/

.b-around-primary-dark-1 {
  border: 0.03125rem solid map-get($colors, primary-dark);
}

.b-around-primary-accent-4 {
  border: 0.125rem solid map-get($colors, primary-accent) !important;
}

.bb-primary-background-pink-2 {
  border-bottom: 0.0625rem solid map-get($colors, primary-background-pink);
}

.b-around-primary-2 {
  border: 0.0625rem solid map-get($colors, primary);
}

.bb-primary-background-pink-4 {
  border-bottom: 0.125rem solid map-get($colors, primary-background-pink);
}

.bb-primary-divider-1 {
  border-bottom: 0.03125rem solid map-get($colors, primary-divider);
}

.bb-primary-divider-4 {
  border-bottom: 0.125rem solid map-get($colors, text-field-border) !important;
}

.bb-primary-divider-left {
  border-left: 0.5px solid #DCE1E9;
}

.bb-primary-divider-top {
  border-right: 0.125rem solid map-get($colors, text-field-border) !important;
}

.bb-primary-divider-right {
  border-right: 0.5px solid #DCE1E9;
}

.bb-primary-divider-bottom {
  border-bottom: 0.5px solid #DCE1E9;
}

.msg-composer-secure-file-border {
  border-bottom: 0.5px solid #DCE1E9;
  border-top: 0.5px solid #DCE1E9;
  border-left: 0.5px solid #DCE1E9;
  border-right: 0.5px solid #DCE1E9;
}

.bb-primary-divider-5 {
  border-bottom: 0.125rem solid map-get($colors, border-color);
}

.border-primary-divider {
  border: 0.0625rem solid map-get($colors, primary-divider);
}
.header-button-border{
  border: 0.0625rem solid map-get($colors, after-hours-button-border);
}
//new theme border
.b-around-text-field-border-4 {
  border: 0.0625rem solid map-get($colors, text-field-border) !important;
}

.bb-display-background-4 {
  border-bottom: 0.125rem solid map-get($colors, display-background);
}

.brr-2 {
  border-radius: 0 1rem 1rem 0;
}

.brl-2 {
  border-radius: 1rem 0 0 1rem !important;
}

.br-0_2 {
  border-radius: 0.2rem;
}

.brl-1 {
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.brr-1 {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.b-none {
  border: none !important;
}

.br-p-3 {
  border-radius: 3% !important;
}

.bb-primary-divider-2 {
  border-bottom: 0.05rem solid map-get($colors, text-field-border) !important;
}

/*Icon styleing*/
.icon {
  &-primary:hover {
    background: map-get($colors, primary-icon-hover) 0% 0% no-repeat padding-box;
    border-radius: 40%;
  }

  &-allTimeHover {
    background: map-get($colors, primary-icon-hover) 0% 0% no-repeat padding-box;
    border-radius: 40%;
    padding: 4px;
  }

  &-secondary:hover {
    background: map-get($colors, secondary-icon-hover) 0% 0% no-repeat padding-box;
    border-radius: 50%;
  }
}

.h1-title {
  font: map-get($fonts, h1-regular);

  @media (max-width: 835px) {
    font: map-get($fonts-mobile, h1);
    font-size: 20px;
  }
}

.switch {
  width: 3rem;
  height: 1.375rem;
  display: inline-block;
  cursor: pointer;

  &>.checked {
    background-color: map-get($colors, checkbox-checked);
    display: inline-flex;
    width: inherit;
    border-radius: 0.6875rem;

    &>.circle {
      height: 1rem;
      width: 1rem;
      display: inline-flex;
      border-radius: 0.5rem;
      background-color: map-get($colors, primary-background);
      margin-top: 3px;
      margin-bottom: 3px;
      transform: translateX(25px);
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;

      &>span {
        width: 0.16rem;
        height: 0.6rem;
        background-color: #1450ff;
        display: inline-block;
        margin: 0.2rem 0.42rem;
      }
    }
  }

  &>.un-checked {
    background-color: map-get($colors, checkbox-notchecked);
    display: inline-flex;
    width: inherit;
    border-radius: 0.6875rem;

    &>.circle {
      height: 1rem;
      width: 1rem;
      display: inline-flex;
      border-radius: 0.5rem;
      margin: 3px;
      background-color: map-get($colors, primary-background);
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }
  }
}

.header-dropdown {
  z-index: 10000;
  margin-top: 0.25rem;
  box-shadow: 0 1rem 2.5rem rgba(112, 144, 176, 0.2) !important;
}

.page-not-found-border {
  border: 1px solid map-get($colors, conv-hist-border);
}

.import-contacts-border {
  border: 2px dashed map-get($colors, conv-hist-border) !important;
  ;
}

.disableEdgeIcon .MuiInputBase-root .MuiInputBase-input::-ms-reveal {
  display: none;
}