$color-error: #E61C3D;
$color-white: #fff;

.icon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    overflow: hidden;
}

.alert-bubble {
    position: relative;
    // padding-left: 32px;
    // margin: 2em;

    .icon {
        display: block;
        position: absolute;
        color: map-get($colors, nav-bar-highlight);
        right: 1.5rem;
        height: 20px;
        width: 20px;
        top: 0.2rem;
        animation: bell-in 1800ms cubic-bezier(.68, -0.55, .27, 1.55) infinite;
        opacity: 0;
    }

    .bell-a {
        transform-origin: center center;
        animation: bell-buzz 900ms ease-in infinite;
    }

    .bubble {
        animation: bubble-in 1800ms cubic-bezier(1, 0, 0, 1) infinite;
    }
}

@keyframes bubble-in {
    0% {
        transform: scale(0);
    }

    66% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes bell-in {
    0% {
        transform: translateX(150%) scale(0);
        opacity: 0;
    }

    50% {
        transform: translateX(150%) scale(.5);
        opacity: 0;
    }

    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }
}

@keyframes bell-buzz {

    25% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(-30deg);
    }

    75% {
        transform: rotateZ(45deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}