.bs-none {
    box-shadow: none !important;
}

.container {
    height: 72vh;
    width: -webkit-fill-available
}

// .file-upload {
//     width: 6rem;
//     padding: 0.5rem;
//     border-radius: 0.5rem;
//     background-image: linear-gradient(to bottom, $duodenary 50%, $secondary 50%);
//     background-size: 1rem 5rem;
//     color: $secondary;
//     cursor: pointer !important;
//     font: $font-contact-add-button;

//     &:hover {
//         background: $duodenary-active 0% 0% no-repeat padding-box !important;
//         box-shadow: 0px 8px 24px $duodenary-light;
//     }
// }

.choose-file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer !important;
}

.stickyContact {
    position: sticky !important;
    position: -webkit-sticky;
    top: 0 !important;
}

.tableFixHeader thead th {
    position: sticky;
    top: 0px;
    z-index: 1;
}

table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
}

.table-contact-header {
    &-cell:first-child {
        border-radius: 1rem 0rem 0rem 0rem;
    }

    &-cell:last-child {
        border-radius: 0rem 0rem 1rem 0rem;
    }
}

.menu-margin {
    margin: 1.3rem 1rem 1rem 1rem !important;
}

.burger-menu-margin {
    margin: 0.3rem 1rem 1rem 1rem !important;
}

.contact-title-header {
    margin: 1rem 1rem 0 1rem;
    padding-left: 2rem;
}


@each $name,
$bgcolor in $status-colors {
    .ticket-variant-#{$name} {
        display: inline-flex;
        border-radius: 0.5rem;
        border:0.125rem solid map-get($status-colors, #{$name}-br);
        background: $bgcolor 0% 0% no-repeat padding-box;
        padding: 0.025rem 0.5rem !important;
        color: map-get($status-colors, #{$name}-font);
    }
}

@each $name,
$bgcolor in $status-colors {
    .bg-ticket-variant-#{$name} {
        background: map-get($status-colors, #{$name}) 0% 0% no-repeat padding-box;
        color: map-get($status-colors, #{$name}-font) !important;
    }
}


// @each $name,
// $bgcolor in $grid-colors {
//     .status-variant-#{$name} {
//         display: inline-flex;
//         border-radius: 0.25rem;
//         border:0.125rem solid map-get($grid-colors, #{$name}-br);
//         background: $bgcolor 0% 0% no-repeat padding-box;
//         padding: 0.025rem 0.5rem !important;
//         color: map-get($grid-colors, #{$name}-font);
//     }
// }